import Paid from "@mui/icons-material/Paid";
import { Button } from "@mui/material";
import { IconCoin } from "@tabler/icons-react";

import { MoneyIconOutlined } from "~/components/icons";
import type { SearchedGifter } from "~/modules/search/types";

import { CreateCartRequestProvider, useCreateCartRequest } from "./create-cart-request-context";
import { CreateDialog } from "./create-dialog";
import { CreateFailedDialog } from "./create-failed-dialog";
import { ShareDialog } from "./share-dialog";
import { SummaryDialog } from "./summary-dialog";

type Gifter = Pick<SearchedGifter, "id" | "name" | "handle">;

type CreateCartRequestButtonProps =
  | { from: "wisher"; gifter?: never; fromOrder?: never; pseudonym?: never }
  | { from: "requests-centre"; gifter?: never; fromOrder?: never; pseudonym?: never }
  | { from: "gifter"; gifter: Gifter; fromOrder?: never; pseudonym?: never }
  | { from: "order"; gifter?: Gifter; fromOrder: string; pseudonym?: string };

function CreateCartRequestButton({
  from,
  gifter,
  fromOrder,
  pseudonym,
}: CreateCartRequestButtonProps) {
  const { setValues } = useCreateCartRequest();
  function handleOpenDialog() {
    return setValues({ step: "create", gifter, fromOrder, pseudonym });
  }

  switch (from) {
    case "wisher": {
      return (
        <Button
          variant="outlined"
          sx={{ minWidth: "200px" }}
          endIcon={<IconCoin size={20} />}
          onClick={handleOpenDialog}
        >
          Create Request
        </Button>
      );
    }

    case "requests-centre":
      return (
        <Button
          sx={{
            textTransform: "capitalize",
            border: (theme) => `1px solid ${theme.palette.teal[4]}`,
            px: 2,
            fontSize: 14,
            fontWeight: 600,
          }}
          variant="contained"
          onClick={handleOpenDialog}
          endIcon={<MoneyIconOutlined style={{ marginLeft: 1 }} />}
        >
          Create new request
        </Button>
      );

    case "gifter":
    case "order": {
      return (
        <Button variant="outlined" endIcon={<Paid />} onClick={handleOpenDialog}>
          Send Request
        </Button>
      );
    }
  }
}

/**
 * from wisher own profile
 */
function CreateCartRequestFromWisherProfileDialog() {
  const { setValues } = useCreateCartRequest();
  function onClose() {
    setValues({ step: "hidden", gifter: undefined, request: undefined });
  }

  return (
    <>
      <CreateDialog
        onClose={onClose}
        onSuccess={(cartRequest) => setValues({ step: "share", request: cartRequest })}
      />
      <ShareDialog
        onClose={onClose}
        onSuccess={(gifter) => setValues({ step: "summary", gifter })}
      />
      <CreateFailedDialog />
      <SummaryDialog onClose={onClose} />
    </>
  );
}

/**
 * from gifter profile
 */
function CreateCartRequestFromGifterProfileDialog() {
  const { setValues } = useCreateCartRequest();

  return (
    <>
      <CreateDialog
        onClose={() => setValues({ step: "hidden" })}
        onSuccess={() => {
          setValues({ step: "summary" });
        }}
      />
      <CreateFailedDialog />
      <SummaryDialog onClose={() => setValues({ step: "hidden" })} />
    </>
  );
}

export const CreateCartRequest = {
  Provider: CreateCartRequestProvider,
  Button: CreateCartRequestButton,
  WisherProfileDialog: CreateCartRequestFromWisherProfileDialog,
  GifterProfileDialog: CreateCartRequestFromGifterProfileDialog,
  useCreateCartRequest: useCreateCartRequest,
};
