import { CircularProgress } from "@mui/material";
import { IconCircleCheckFilled, IconLink } from "@tabler/icons-react";

import { BounceInWidth } from "~/components/animations/bounce-in-width";
import { Button } from "~/components/button";
import { useCopyWithLoading } from "~/hooks/use-copy-with-loading";

export function CopyRequestLinkButton({ url }: { url: string }) {
  const [copyState, copyToClipboard] = useCopyWithLoading();

  function copyButtonLabel() {
    if (copyState === "loading") return <span>Copying...</span>;
    if (copyState === "success") return <span>Copied Request Link</span>;
    return <span>Copy Request Link</span>;
  }

  function copyButtonIcon() {
    if (copyState === "loading") return <CircularProgress size={20} />;
    if (copyState === "success") {
      return (
        <BounceInWidth sx={{ display: "inline-flex" }}>
          <IconCircleCheckFilled />
        </BounceInWidth>
      );
    }
    return <IconLink />;
  }

  return (
    <Button
      variant="outlined"
      onClick={async () => {
        if (copyState !== "idle") return;
        await copyToClipboard(url);
      }}
      startIcon={copyButtonIcon()}
    >
      {copyButtonLabel()}
    </Button>
  );
}
