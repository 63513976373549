import { SvgIcon, type SvgIconProps } from "@mui/material";

export function AlertDangerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00086 0.00488281C3.58362 0.00488281 0.00585938 3.58265 0.00585938 7.99989C0.00585938 12.4171 3.58362 15.9949 8.00086 15.9949C12.4181 15.9949 15.9959 12.4171 15.9959 7.99989C15.9959 3.58265 12.4181 0.00488281 8.00086 0.00488281ZM7.00149 12.9968V10.998H9.00024V12.9968H7.00149ZM7.00149 3.00301V9.99864H9.00024V3.00301H7.00149Z"
        fill="#D14343"
      />
    </SvgIcon>
  );
}
