import { Button, Stack, ThemeProvider } from "@mui/material";

import { Dialog } from "~/components/dialog";
import { themeV2 } from "~/utils/theme";

import { useCreateCartRequest } from "./create-cart-request-context";

export function CreateFailedDialog() {
  const { step, setValues } = useCreateCartRequest();

  function onClose() {
    setValues({ step: "hidden" });
  }

  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root
        variant="alert"
        open={step === "create-failed"}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        aria-describedby="alert-dialog-create-cart-request-failed"
      >
        <Dialog.Title sx={{ pt: 5, pb: 3, typography: "h6" }}>Request Failed</Dialog.Title>
        <Dialog.Content sx={{ px: 4 }}>
          <Stack spacing={2}>
            <Dialog.ContentText
              id="alert-dialog-create-cart-request-failed"
              sx={{ typography: "b5" }}
            >
              You can’t send requests to this account right now.
            </Dialog.ContentText>
            <Dialog.Actions sx={{ pt: 3 }}>
              <Button variant="contained" type="button" onClick={onClose} fullWidth>
                Finish
              </Button>
            </Dialog.Actions>
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
    </ThemeProvider>
  );
}
