import { Box, Stack, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { IconCircleCheckFilled, IconInfoCircleFilled } from "@tabler/icons-react";

import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { MoneyWithOriginal } from "~/components/money";
import { Routes } from "~/constants/routes";
import { usePresentmentCurrency } from "~/modules/currency";
import { themeV2 } from "~/utils/theme";

import { useCreateCartRequest } from "./create-cart-request-context";

type SummaryDialogProps = { onClose: () => void };
export function SummaryDialog({ onClose }: SummaryDialogProps) {
  const currency = usePresentmentCurrency("USD");
  const { step, request, gifter, fromOrder, pseudonym } = useCreateCartRequest();

  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root
        variant="normal"
        open={step === "summary" && !!request}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
      >
        <Dialog.Title sx={{ pt: 4.5, pb: 3, fontSize: 24 }}>Request Sent</Dialog.Title>
        <Dialog.Content sx={{ pt: 0 }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              borderRadius: 40,
              background: "linear-gradient(180deg, #D3EEF7 0%, #F2FAFD 100%)",
            }}
          >
            <IconCircleCheckFilled
              style={{ color: themeV2.palette.teal[4], verticalAlign: "top" }}
            />
          </Box>

          <Box mt={2} />

          <Stack direction="row" sx={{ justifyContent: "center", alignItems: "center", gap: 1 }}>
            <Typography component="p" variant="sh5" sx={{ textAlign: "center" }}>
              You requested&nbsp;
              <MoneyWithOriginal
                money={{ amount: request?.lineItems[0].tipAmount ?? 0, currency }}
              />
              <span> from </span>
              {gifter ? (
                <Link
                  className="notranslate"
                  style={{ color: themeV2.palette.teal[4] }}
                  to={Routes.public.gifter({ username: gifter.handle })}
                >
                  @{gifter.handle}
                </Link>
              ) : (
                <Typography variant="sh5" component="span" color="neutral.5">
                  {pseudonym || "Anonymous"}
                </Typography>
              )}
            </Typography>
            {!!fromOrder && !gifter && (
              <Tooltip title={<span>Order ID: {fromOrder}&apos;s gifter</span>} enterTouchDelay={0}>
                <IconInfoCircleFilled size={20} style={{ color: themeV2.palette.neutral[5] }} />
              </Tooltip>
            )}
          </Stack>

          <Box mt={2} />

          <Stack
            sx={{
              p: 1,
              border: (theme) => `1px solid ${theme.palette.neutral[2]}`,
              borderRadius: 2,
              background: themeV2.palette.neutral[1],
              gap: 1.25,
            }}
          >
            <Stack sx={{ p: 1 }}>
              <Typography variant="b5" color="neutral.5">
                For
              </Typography>
              <Typography variant="b5" color="neutral.7">
                {request?.lineItems[0].tipNote}
              </Typography>
            </Stack>
            <Stack sx={{ p: 1 }}>
              <Typography variant="b5" color="neutral.5">
                Request Note
              </Typography>
              <Typography variant="b5" color="neutral.7">
                {request?.note}
              </Typography>
            </Stack>
          </Stack>

          <Box mt={5} />

          <Button fullWidth variant="contained" onClick={onClose}>
            Done
          </Button>
        </Dialog.Content>
      </Dialog.Root>
    </ThemeProvider>
  );
}
