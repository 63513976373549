import type { PropsWithChildren } from "react";
import { createContext, useContext, useReducer } from "react";

import type { SearchedGifter } from "~/modules/search/types";

type Gifter = Pick<SearchedGifter, "id" | "name" | "handle">;
export type Values = {
  step: "hidden" | "create" | "share" | "summary" | "create-failed";
  request?: {
    id: string;
    lineItems: { tipAmount: number; tipNote?: string }[];
    gifter?: { pseudonym: string };
    note?: string;
  };
  gifter?: Gifter;
  fromOrder?: string;
  pseudonym?: string;
};
type CreateCartRequestContextValues = Values & {
  setValues: (update: Partial<Values>) => void;
};

const CreateCartRequestContext = createContext<CreateCartRequestContextValues | string>(
  "useCreateCartRequest must be used within a CreateCartRequestProvider",
);

export function CreateCartRequestProvider({ children }: PropsWithChildren) {
  const [values, setValues] = useReducer(
    (state: Values, update: Partial<Values>) => ({
      ...state,
      ...update,
    }),
    { step: "hidden" },
  );

  return (
    <CreateCartRequestContext.Provider value={{ ...values, setValues }}>
      {children}
    </CreateCartRequestContext.Provider>
  );
}

export function useCreateCartRequest() {
  const context = useContext(CreateCartRequestContext);
  if (typeof context === "string") {
    throw new TypeError(context);
  }
  return context;
}
