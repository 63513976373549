import type { ListItemButtonProps } from "@mui/material";
import {
  Autocomplete,
  Box,
  InputAdornment,
  ListItemButton,
  Stack,
  TextField as MTextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { IconCircleCheckFilled, IconSearch } from "@tabler/icons-react";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";

import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { AlertDangerIcon } from "~/components/icons/alert-danger-icon";
import { Routes } from "~/constants/routes";
import { useOrigin } from "~/hooks/use-origin";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import type { SearchedGifter, SearchGifterResult } from "~/modules/search/types";
import { themeV2 } from "~/utils/theme";

import { CopyRequestLinkButton } from "./copy-request-link-button";
import { useCreateCartRequest } from "./create-cart-request-context";

type ShareDialogProps = {
  onClose: () => void;
  onSuccess: (gifter: SearchedGifter) => void;
};

export function ShareDialog({ onClose, onSuccess }: ShareDialogProps) {
  const { step } = useCreateCartRequest();
  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root
        variant="normal"
        open={step === "share"}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
      >
        <Dialog.Title sx={{ pt: 5, fontSize: 24 }}>Request Created</Dialog.Title>
        <ShareForm onClose={onClose} onSuccess={onSuccess} />
      </Dialog.Root>
    </ThemeProvider>
  );
}

// Keep the form in a separate component will help reset internal state when the dialog is closed
function ShareForm({ onSuccess }: ShareDialogProps) {
  const origin = useOrigin();
  const { request } = useCreateCartRequest();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 250);
  const [error, setError] = useState<string>();
  const [gifter, setGifter] = useState<SearchedGifter>();

  const [searchGifters, searchGiftersState] = useWtFetcher<SearchGifterResult>({});
  const gifters = debouncedSearch === "" ? [] : searchGifters.data?.gifters ?? [];

  useEffect(() => {
    if (error) setError(undefined);
    if (!debouncedSearch) return;
    searchGifters.load(Routes.loaders.search.gifters({ query: debouncedSearch }));
    // prevent infinite loop when `searchGifters.load()` changes `searchGifters` itself
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const [share, shareState] = useWtFetcher({
    onSuccess: () => {
      if (gifter) {
        onSuccess(gifter);
      }
    },
    onError: (response) => {
      setError(response.error);
    },
  });

  const url = `${origin}${Routes.cart.request({ request: request?.id ?? "" })}`;

  return (
    <share.Form action={Routes.actions.wisher.cart.request.share()} method="post">
      <Dialog.Content sx={{ pt: 0 }}>
        <input type="hidden" name="requestId" value={request?.id} />
        <input type="hidden" name="gifterId" value={gifter?.id} />
        <Stack
          spacing={1}
          direction="row"
          sx={{ alignItems: "center", justifyContent: "center", color: "teal.4" }}
        >
          <IconCircleCheckFilled />
          <Typography component="span" variant="sh5" sx={{ color: "neutral.7" }}>
            Your wish request is good to go!
          </Typography>
        </Stack>

        <Box mt={4} />

        <Stack spacing={2}>
          <Autocomplete
            loading={searchGiftersState.isFetching}
            id="select-gifter"
            fullWidth
            disablePortal
            options={gifters}
            value={gifter}
            popupIcon={<></>}
            onChange={(_, value) => {
              if (error) setError(undefined);
              setGifter(value ?? undefined);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <ListItemButton {...(props as ListItemButtonProps)} disabled={!option.canSendRequest}>
                <Stack>
                  <Typography
                    className="notranslate"
                    variant="sh4"
                    component="span"
                    textAlign="left"
                  >
                    {option.name}
                  </Typography>
                  <Typography
                    className="notranslate"
                    variant="b4"
                    component="span"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `@${option.highlight?.handleAnalyzed ?? option.handle} ${
                          option.canSendRequest ? "" : "cannot be sent requests."
                        }`,
                      ),
                    }}
                  />
                </Stack>
              </ListItemButton>
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.handle.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.name.toLowerCase().includes(inputValue.toLowerCase()),
              )
            }
            renderInput={(props) => (
              <MTextField
                {...props}
                sx={{
                  "& .MuiInputBase-root": {
                    py: "5px",
                    px: 2,
                  },
                }}
                InputProps={{
                  ...props.InputProps,
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      {gifter ? (
                        <Typography variant="b5">Sending to</Typography>
                      ) : (
                        <IconSearch style={{ color: themeV2.palette.neutral[5] }} />
                      )}
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: 24,
                    typography: gifter ? "h5" : "b5",
                    bgcolor: "neutral.2",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover": {
                      "&& fieldset": {
                        borderColor: "teal.4",
                      },
                    },
                    "& .MuiInputBase-root": {
                      pl: 5,
                    },
                  },
                }}
                placeholder="Search for a gifter"
                onChange={(event) => setSearch(event.target.value)}
              />
            )}
            noOptionsText={debouncedSearch ? "No gifters found" : "Start typing to search"}
          />
          {!!error && (
            <Stack
              direction="row"
              spacing={1.5}
              sx={{
                alignItems: "center",
                border: (theme) => `1px solid ${theme.palette.error[5]}`,
                color: "error.5",
                borderRadius: 2,
                p: 1.5,
              }}
            >
              <AlertDangerIcon sx={{ fontSize: 16 }} />
              <Typography variant="sh4">{error}</Typography>
            </Stack>
          )}
          <Button
            disabled={!!error || shareState.isFetching || !gifter}
            variant="contained"
            type="submit"
            loading={shareState.isFetching}
            size="lg"
            sx={{ height: 48 }}
          >
            <span>Send</span>
          </Button>
          <Typography component="span" variant="b5" alignSelf="center" sx={{ py: 1 }}>
            or
          </Typography>
          <Typography variant="h7" color="neutral.6" alignSelf="center">
            Share a link to your request{" "}
          </Typography>
          <Typography color="neutral.5" variant="b5" textAlign="center">
            If your recipient doesn’t have a gifter account, you can give them this link.
          </Typography>
          <CopyRequestLinkButton url={url} />
        </Stack>
      </Dialog.Content>
    </share.Form>
  );
}
