import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Link } from "@remix-run/react";
import { IconCircleCheckFilled, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { NumberFormatInput } from "~/components/form/number-format-input";
import { TextField } from "~/components/form/text-field";
import { UserIcon } from "~/components/icons/user-icon";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import {
  currencyInfo,
  toSmallestUnit,
  unitToStandard,
  usePresentmentCurrency,
} from "~/modules/currency";
import { createCartRequestSchema, type tipLineItemSchema } from "~/modules/wisher-requests/schemas";
import { themeV2 } from "~/utils/theme";

import type { Values } from "./create-cart-request-context";
import { useCreateCartRequest } from "./create-cart-request-context";

type FormValues = z.infer<typeof createCartRequestSchema>;
type TipLineItem = z.infer<typeof tipLineItemSchema> & { quantity: number };

export type CreateDialogProps = {
  onClose: () => void;
  onSuccess: (cartRequest: any) => void;
  loading?: boolean;
};

export function CreateDialog({ onClose, onSuccess, loading }: CreateDialogProps) {
  const currency = usePresentmentCurrency("USD");
  const { step, gifter, fromOrder, pseudonym, setValues } = useCreateCartRequest();
  const [amountError, setAmountError] = useState("");
  const [templateToDelete, setTemplateToDelete] = useState<string | undefined>();
  const [deletedTemplateName, setDeletedTemplateName] = useState<string | undefined>();
  const form = useForm<FormValues>({
    resolver: zodResolver(createCartRequestSchema),
    defaultValues: {
      lineItems: [{ type: "tip", tipAmount: "", tipNote: "", quantity: 1 }],
      templateId: "",
      note: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [hasNote, setHasNote] = useState(false);
  const [saveForRepeatUse, setSaveForRepeatUse] = useState(false);
  useEffect(() => {
    if (step === "hidden") {
      form.reset();
      setHasNote(false);
      setSaveForRepeatUse(false);
      setAmountError("");
    }
  }, [form, step]);

  const [createGiftRequest, createState] = useWtFetcher({
    onSuccess: (response) => {
      const cartRequest = (response.data as { cartRequest: Values["request"] }).cartRequest;
      setValues({ request: cartRequest });
      onSuccess(cartRequest);
    },
    onError: (response) => {
      // CURRENTLY WE DO NOT HAVE GOOD ERROR HANDLING SYSTEM ENOUGH
      // WE JUST SEND THE MESSAGE OF ERROR. SO FOR EACH OF ERROR, WE CAN'T CHECK THE MESSAGE FOR LOGIC, WE SHOULD HAVE ERROR_CODE OR SOMETHING ELSE
      // THIS SOLUTION IS JUST A QUICK FIX. NEED DISCUSS MORE!
      if (response.error?.includes("Minimum amount is")) {
        setAmountError(response.error);
      } else {
        setValues({ step: "create-failed" });
      }
    },
  });

  const [archiveRequestTemplate, archiveRequestTemplateState] = useWtFetcher({
    onSuccess: () => {
      setDeletedTemplateName(
        getTemplates.data?.templates?.find((template) => template._id === templateToDelete)?.name,
      );
      setTemplateToDelete(undefined);
    },
    onError: (response) => {
      alert(response.error);
    },
  });

  const [getTemplates] = useWtFetcher<{ templates: any[] }>();
  useEffect(() => {
    getTemplates.load(Routes.loaders.wisher.requests.templates());
    // prevent infinite loop when `getTemplates.load()` changes `getTemplates` itself
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templatesMap = getTemplates.data?.templates?.reduce((map, template) => {
    map.set(template._id.toString(), template);
    return map;
  }, new Map());

  const handleSubmit = form.handleSubmit((values) => {
    const { lineItems, note, templateName, templateId } = values;
    const createRequestPayload: FormValues = {
      // currently, we're only supporting one line item of type "tip"
      lineItems: (lineItems as TipLineItem[]).map((lineItem) => ({
        ...lineItem,
        tipAmount: toSmallestUnit(lineItem.tipAmount, currency),
      })),
      note: hasNote ? note : undefined,
      templateName: saveForRepeatUse ? templateName : undefined,
      templateId,
      gifterId: gifter?.id,
      fromOrder,
    };
    createGiftRequest.submit(
      { body: JSON.stringify(createRequestPayload) },
      {
        action: Routes.actions.wisher.cart.request.create(),
        method: "post",
      },
    );
  });
  const templateId = form.watch("templateId");

  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root
        variant="normal"
        open={step === "create"}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
      >
        <Dialog.Title style={{ fontSize: 24 }}>
          {gifter ? <span>Send Wish Request</span> : <span>Create Wish Request</span>}
        </Dialog.Title>
        <Dialog.Content>
          <Stack spacing={2} component="form" onSubmit={handleSubmit}>
            {gifter || fromOrder ? (
              <Box display="flex" alignItems="center" gap={1}>
                <UserIcon />
                <Typography variant="b5" color="neutral.7">
                  You're sending a request to&nbsp;
                  {gifter ? (
                    <Link
                      className="notranslate"
                      style={{ color: themeV2.palette.teal[4], fontWeight: 600 }}
                      to={Routes.public.gifter({ username: gifter.handle })}
                    >
                      @{gifter.handle}
                    </Link>
                  ) : (
                    <Typography variant="h5" color="neutral.7" component="span">
                      {pseudonym || "Anonymous"}
                    </Typography>
                  )}
                </Typography>
              </Box>
            ) : undefined}

            <Typography variant="sh5" component="span" sx={{ color: "neutral.5" }}>
              Request Details
            </Typography>
            {!!deletedTemplateName && (
              <Stack
                direction="row"
                sx={{
                  p: 1.5,
                  gap: 2,
                  bgcolor: "green.0",
                  border: (theme) => `2px solid ${theme.palette.green[4]}`,
                  borderRadius: 2,
                }}
              >
                <IconCircleCheckFilled style={{ color: themeV2.palette.green[4] }} />
                <Typography color="green.6" sx={{ flex: 1 }}>
                  “{deletedTemplateName}” was deleted.
                </Typography>
                <IconX
                  onClick={() => setDeletedTemplateName(undefined)}
                  style={{ color: themeV2.palette.green[6], cursor: "pointer" }}
                />
              </Stack>
            )}

            {templatesMap ? (
              <Controller
                control={form.control}
                name="templateId"
                render={({ field: { onChange, ...field } }) => (
                  <TextField.Root fullWidth>
                    <TextField.Label sx={visuallyHidden} htmlFor="select-template">
                      Template
                    </TextField.Label>
                    <Select
                      id="select-template"
                      {...field}
                      onChange={(event) => {
                        const value = event.target.value;
                        onChange(value);
                        if (value === templateToDelete) setTemplateToDelete(undefined);
                        if (!value) return;
                        const template = templatesMap.get(value);
                        form.setValue("lineItems.0.tipNote", template.lineItems[0].tipNote);
                        form.setValue(
                          "lineItems.0.tipAmount",
                          unitToStandard(template.lineItems[0].tipAmount, currency).toString(),
                        );
                      }}
                      placeholder="Use a saved request"
                      MenuProps={{
                        sx: {
                          "& .MuiList-root.MuiMenu-list": {
                            padding: 0,
                          },
                          typography: "b4",
                          boxShadow: "0px 4px 8px 0px rgba(52, 87, 140, 0.12)",
                          mt: 1,
                          "&& .Mui-selected": {
                            backgroundColor: "teal.1",
                            color: "neutral.7",
                            fontWeight: 600,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiSelect-select .notranslate::after": {
                          content: `"Use a saved request"`,
                        },
                        "&:hover": {
                          "&& fieldset": {
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "teal.4",
                            bgcolor: (theme) => `${theme.palette.teal[2]}10`,
                          },
                        },
                        p: 0,
                      }}
                      renderValue={(value) => {
                        const template = templatesMap.get(value);
                        return (
                          <Typography component="span" variant="sh4">
                            {template.name}
                          </Typography>
                        );
                      }}
                    >
                      <MenuItem
                        value=""
                        sx={{ m: 0, maxWidth: 380, height: 60 }}
                        disabled={archiveRequestTemplateState.isFetching}
                      >
                        <Typography component="span" variant="sh4" sx={{ fontStyle: "italic" }}>
                          None
                        </Typography>
                      </MenuItem>
                      {getTemplates.data?.templates?.map((template) => (
                        <MenuItem
                          key={template._id}
                          value={template._id}
                          disabled={archiveRequestTemplateState.isFetching}
                          sx={{
                            m: 0,
                            pr: 1.5,
                            pl: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            maxWidth: 380,
                            height: 60,
                            gap: 1,
                          }}
                        >
                          <Typography
                            component="span"
                            variant="sh4"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {template.name}
                          </Typography>
                          {templateToDelete && templateToDelete === template._id ? (
                            <Stack direction="row" spacing={1}>
                              <Button
                                type="button"
                                variant="contained"
                                color="inherit"
                                size="small"
                                disabled={archiveRequestTemplateState.isFetching}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setTemplateToDelete(undefined);
                                }}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                type="button"
                                variant="contained"
                                color="error"
                                size="small"
                                disabled={archiveRequestTemplateState.isFetching}
                                loading={archiveRequestTemplateState.isFetching}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  archiveRequestTemplate.submit(
                                    {
                                      templateId: templateToDelete,
                                    },
                                    {
                                      method: "post",
                                      action: Routes.actions.request.archiveTemplate(),
                                    },
                                  );
                                }}
                              >
                                <span>Delete</span>
                              </LoadingButton>
                            </Stack>
                          ) : (
                            <Fade in={template._id !== templateId}>
                              <IconButton
                                disabled={archiveRequestTemplateState.isFetching}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setTemplateToDelete(template._id);
                                }}
                              >
                                <IconTrash style={{ color: themeV2.palette.neutral[5] }} />
                              </IconButton>
                            </Fade>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </TextField.Root>
                )}
              />
            ) : undefined}
            <Controller
              control={form.control}
              name="lineItems.0.tipAmount"
              render={({ field }) => (
                <TextField.Root fullWidth>
                  <TextField.Label sx={{ typography: "sh5", color: "neutral.7" }}>
                    Amount
                  </TextField.Label>
                  <TextField.Input
                    {...field}
                    inputComponent={NumberFormatInput}
                    inputProps={{ decimalPlaces: currencyInfo(currency).decimalPlaces }}
                    startAdornment={
                      <InputAdornment position="start">
                        {currencyInfo(currency).symbol}
                      </InputAdornment>
                    }
                    inputMode="numeric"
                    disabled={!!templateId}
                  />
                  {amountError && <TextField.Helper error>{amountError}</TextField.Helper>}
                </TextField.Root>
              )}
            />
            <Controller
              control={form.control}
              name="lineItems.0.tipNote"
              render={({ field, fieldState: { error } }) => (
                <TextField.Root fullWidth>
                  <TextField.Label sx={{ typography: "sh5", color: "neutral.7" }}>
                    For (Optional)
                  </TextField.Label>
                  <TextField.Input {...field} disabled={!!templateId} />
                  {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                </TextField.Root>
              )}
            />
            <Stack sx={{ pt: 1 }}>
              <Stack spacing={1.5}>
                <FormControlLabel
                  label={
                    <Typography component="span" variant="b5">
                      Add a personal request note
                    </Typography>
                  }
                  control={
                    <Checkbox value={hasNote} onChange={(_, checked) => setHasNote(checked)} />
                  }
                />
                <Collapse in={hasNote}>
                  <Controller
                    control={form.control}
                    name="note"
                    render={({ field, fieldState: { error } }) => (
                      <TextField.Root fullWidth>
                        <TextField.Label sx={visuallyHidden}>Note</TextField.Label>
                        <TextField.Input
                          {...field}
                          multiline
                          minRows={2}
                          maxRows={3}
                          placeholder="Write a message..."
                          sx={{
                            mb: 2,
                          }}
                        />
                        {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                      </TextField.Root>
                    )}
                  />
                </Collapse>
              </Stack>

              <Collapse in={!templateId}>
                <Stack spacing={1.5}>
                  <FormControlLabel
                    label={
                      <Typography component="span" variant="b5">
                        Save this request for repeat use
                      </Typography>
                    }
                    control={
                      <Checkbox
                        value={saveForRepeatUse}
                        onChange={(_, checked) => setSaveForRepeatUse(checked)}
                      />
                    }
                  />
                  <Collapse in={saveForRepeatUse}>
                    <Controller
                      control={form.control}
                      name="templateName"
                      render={({ field, fieldState: { error } }) => (
                        <TextField.Root fullWidth>
                          <TextField.Label sx={visuallyHidden}>Template name</TextField.Label>
                          <TextField.Input {...field} placeholder="Give it a name" />
                          {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                        </TextField.Root>
                      )}
                    />
                  </Collapse>
                </Stack>
              </Collapse>
            </Stack>

            <Box mt={2} />
            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              fullWidth
              disabled={!form.formState.isDirty || !form.formState.isValid}
              loading={createState.isFetching || loading}
              sx={{
                py: 1.5,
                ":disabled": {
                  bgcolor: (theme) => theme.palette.teal[2],
                  color: (theme) => theme.palette.neutral[0],
                },
              }}
            >
              <Typography component="span" variant="h5">
                Create
              </Typography>
            </LoadingButton>
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
    </ThemeProvider>
  );
}
